import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import HomeLanding from "../views/HomeLanding";
import ArticlesSearch from "../views/ArticlesSearch";
import CoursesSearch from "../views/CoursesSearch";

export default function Home(props) {

  const appContext = useContext(AppContext);

  const [view, setView] = useState(null);

  async function init() {
    //run initializations here
  }

  function getView() {
    if (
      !appContext.navSubItem &&
      !appContext.navExtraItem &&
      !appContext.navMoreItem
    ) {
      return <HomeLanding />
    } else if (
      appContext.navSubItem === 'articles-search' &&
      !appContext.navExtraItem &&
      !appContext.navMoreItem
    ) {
      return <ArticlesSearch />
    } else if (
      appContext.navSubItem === 'courses-search' &&
      !appContext.navExtraItem &&
      !appContext.navMoreItem
    ) {
      return <CoursesSearch />
    }

    return null;
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    const _view = getView();
    if (_view) {
      setView(_view);
    }
  }, [appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem])

  //return desired view
  return view;
}