import React, { useRef, useState, useEffect, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import logo from '../assets/logo.png';
import logo_worded_only from '../assets/logo_worded_only.png';
import amka_logo from '../assets/amka_logo.png';
import cert_bg from '../assets/cert_bg.png'; // Import the background image
import { CDN_URL, formatDate } from '../Helpers';
import { STR_DOWNLOAD } from '../Strings';
import { AppContext } from '../App';

const Certificate = ({ courseTitle, studentName, date, type = 'course' }) => {
  const appContext = useContext(AppContext);
  const containerRef = useRef();  // Ref for the container to be printed
  const [imageSrc, setImageSrc] = useState(null);  // State to hold the captured image
  const [isGenerating, setIsGenerating] = useState(true);  // State to track if image is being generated

  // Function to trigger printing
  const handlePrint = useReactToPrint({
    content: () => containerRef.current,  // Print only the container div
    documentTitle: 'Certificate',  // Set a custom title for the printed document
  });

  // Function to capture the certificate as an image and render it
  const generateImage = async () => {
    try {
      const canvas = await html2canvas(containerRef.current, {
        useCORS: true, // Ensure cross-origin resources are allowed
        backgroundColor: null, // Ensure the background is captured correctly
        scale: 2, // Increase the resolution of the canvas capture for better quality
      });
      const image = canvas.toDataURL('image/png'); // Convert the canvas to a PNG image
      setImageSrc(image);  // Set the captured image in the state
      setIsGenerating(false);  // Stop showing the certificate div after image is generated
    } catch (error) {
      console.error("Failed to capture the certificate as an image:", error);
    }
  };

  // Automatically generate the image when the component is rendered
  useEffect(() => {
    generateImage();
  }, []);  // Empty dependency array ensures this runs only once after component mounts

  // Define the title based on the type prop
  const certificateTitle = type === 'course' ? 'Course Completion' : 'Training Program Completion';

  // Styles for container (will be captured as an image)
  const containerStyle = {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    backgroundImage: `url(${cert_bg})`, // Background image applied to the container
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '297mm', // Fixed width for A4
    height: '210mm', // Fixed height for A4 landscape
    margin: '0 auto',  // Center the certificate on the page
    border: '1px solid #000',  // Optional: add a border to see the outline
    display: isGenerating ? 'block' : 'none',  // Hide the certificate after image generation
  };

  const certificateStyle = {
    width: '100%',  // Match container width
    height: '100%',  // Match container height
    padding: '20px',
    position: 'relative',
    overflow: 'hidden',
    textAlign: 'center',
    fontFamily: "'Times New Roman', serif",
  };

  const titleContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  };

  const firstPartTitleStyle = {
    fontSize: '50px', // Fixed font size for title
    color: 'var(--primaryColor)', // Primary color for first part of title
    fontWeight: 'bold',
    marginBottom: '0',
    marginTop: '60px',
  };

  const secondPartTitleStyle = {
    fontSize: '28px', // Fixed font size for the second part of the title
    fontWeight: 'bold',
    marginTop: '30px',
  };

  const nameStyle = {
    fontSize: '30px', // Fixed font size for student name
    fontWeight: 'bold',
    marginBottom: '30px',
    color: 'var(--primaryColor)',
  };

  const courseTitleStyle = {
    fontSize: '24px',  // Fixed font size for course title
    fontWeight: 'bold',
    marginBottom: '20px',
  };

  const dateStyle = {
    fontSize: '18px',
    marginBottom: '30px',
  };

  const textStyle = {
    fontSize: '18px',
    marginBottom: '10px',
  };

  const signatureContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    padding: '60px',
    paddingLeft: "220px",
    paddingRight: "220px",
  };

  const signatureStyle = {
    textAlign: 'center',
  };

  const signatureNameStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
  };

  const signatureRoleStyle = {
    fontSize: '16px',
    marginTop: '5px',
  };

  const signatureImageStyle = {
    width: '150px',
    height: 'auto',
    display: 'block',
    margin: '10px auto',
    borderBottom: '1px solid black',
  };

  const logoStyle = {
    width: '60px',
    height: 'auto',
    alignSelf: 'center',
    marginTop: '0',
    marginBottom: '0',
  };

  const leftLogoStyle = {
    ...logoStyle,
    position: 'absolute',
    left: '20px',
    top: '60px',
    width: '320px',
  };

  const rightLogoStyle = {
    ...logoStyle,
    position: 'absolute',
    right: '0px',
    top: '0px',
    width: '300px',
  };

  return (
    <div style={{ width: "100%" }}>
      {/* Display the captured image */}
      {imageSrc ? (
        <div style={{ textAlign: 'center' }}>
          <img src={imageSrc} alt="Certificate Image" style={{ width: '100%', height: 'auto' }} />
          {/* Button to trigger the print */}
          <button className='btn btn-secondary btn-sm btn-rounded' onClick={handlePrint} style={{ marginTop: '20px' }}>
            {STR_DOWNLOAD[appContext.language]}
          </button>
        </div>
      ) : (
        <div className='form-text'>Generating certificate image, please wait...</div>
      )}

      {/* The hidden certificate div to be captured and rendered as an image */}
      <div ref={containerRef} style={containerStyle} className="printable-certificate">
        <div style={certificateStyle}>

          {/* Logos aligned with title */}
          <img style={leftLogoStyle} src={amka_logo} alt="Left Logo" />
          <img style={rightLogoStyle} src={logo} alt="Right Logo" />

          {/* Certificate Title */}
          <div style={titleContainerStyle}>
            <div>
              <div style={firstPartTitleStyle}>Certificate of</div>
              <div style={secondPartTitleStyle}>{certificateTitle}</div>
            </div>
          </div>

          {/* Instruction */}
          <div style={textStyle}>
            This is to certify that
          </div>

          {/* Student Name */}
          <div style={nameStyle}>
            {studentName}
          </div>

          {/* Course/Program Title */}
          <div style={textStyle}>
            has successfully completed the {type === 'course' ? 'course' : 'training program'}
          </div>

          <div style={courseTitleStyle}>
            {courseTitle}
          </div>

          {/* Completion Date */}
          <div style={dateStyle}>
            on {formatDate(date).substring(0, 10)}
          </div>

          {/* Signature Section */}
          <div style={signatureContainerStyle}>
            {/* First Signature */}
            <div style={signatureStyle}>
              <div style={signatureNameStyle}>{appContext.systemParams.signatory1Name}</div>
              <img style={signatureImageStyle} src={(appContext.systemParams.signatory1Signature) ? CDN_URL + appContext.systemParams.signatory1Signature : logo_worded_only} />
              <div style={signatureRoleStyle}>{appContext.systemParams.signatory1Role}</div>
            </div>

            {/* Second Signature */}
            <div style={signatureStyle}>
              <div style={signatureNameStyle}>{appContext.systemParams.signatory2Name}</div>
              <img style={signatureImageStyle} src={(appContext.systemParams.signatory2Signature) ? CDN_URL + appContext.systemParams.signatory2Signature : logo_worded_only} />
              <div style={signatureRoleStyle}>{appContext.systemParams.signatory2Role}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Media query to hide certificate on screen but show when printing */}
      <style>
        {`
          @media print {
            .printable-certificate {
              display: block !important;
            }
            /* Force landscape orientation */
            @page {
              size: A4 landscape;
            }

            /* Enable printing background images (browser dependent, might be ignored) */
            body {
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Certificate;
