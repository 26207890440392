import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { formatDate, formatMoney, removeSlashes } from "../Helpers";
import { STR__ORDER_STATUS_INQUIRY, STR_DOWNLOAD, STR_OPEN, STR_ORDER_STATUS_CANCELLED, STR_ORDER_STATUS_DELIVERED, STR_ORDER_STATUS_PENDING, STR_ORDER_STATUS_SUCCESS } from "../Strings";

export default function View({ data, bookIndex = null, }) {

  const appContext = useContext(AppContext);

  /* Place states here */

  function open() {
    //launch book reader
    appContext.navTo({
      item: "view",
      subItem: "book-reader",
      extraItem: removeSlashes(data.itemFile),
    })

  }

  function download() {
    //..
  }

  return (
    <div className="One">
      <div className="row">
        <div className="col-12 col-md-12 mb-4">
          <h6
            style={{
              fontSize: "22px"
            }}
            className="font-semi-bold"
          >
            {data.itemTitle}

          </h6>
        </div>

        <div className="col-4 col-md-4">
          <h6
            style={{
              fontSize: "30px"
            }}
            className="font-light"
          >
            {data.quantity}

            <span
              style={{
                display: "block",
                fontSize: "12px"
              }}
              className="font-regular"
            >
              {data.unit}
            </span>
          </h6>
        </div>



        <div className="col-12 mt-4">
          <h6
            style={{
              fontSize: "12px"
            }}
            className="font-light"
          >

            {formatDate(data.dateDelivered)}

          </h6>

          {
            (data.itemType === 'digital') ?
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-secondary btn-sm btn-rounded m-2"
                  onClick={open}
                >
                  {STR_OPEN[appContext.language]}
                </button>

                <button
                  className="btn btn-secondary btn-sm btn-rounded m-2"
                  onClick={download}
                  style={{
                    display: "none",
                  }}
                >
                  {STR_DOWNLOAD[appContext.language]}
                </button>
              </div>
              : ""
          }
        </div>

      </div>
    </div>
  )

}