import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, CDN_URL, formatDate, formatMoney, formatMoneyShorten, shuffleArray, stringToArray } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import SubscriptionExpiredWidget from '../ones/SubscriptionExpiredWidget';
import { STR_BACK_TO_COURSE, STR_SELECT_CORRECT_ANSWER, STR_SUBMIT, STR_SUCCESSFUL, STR_TAKE_QUIZ, STR_WRITE_CORRECT_ANSWER, STR_YOUR_MARKS_WILL_APPEAR_HERE_AFTER_COMPLETING_QUIZ, STR_YOUR_RESULTS } from "../Strings";


export default function View({ contentId, courseId }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [contentData, setContentData] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [quiz, setQuiz] = useState([]);
  const [quizResults, setQuizResults] = useState(null);
  const [loading, setLoading] = useState(false);


  async function init() {
    //run initializations here
    setReady(false);
    await Promise.all([
      getCourseData(),
      getContentData(),
    ])
    setReady(true);
  }

  function handleQuizAnswering(_index, _userAnswer) {
    setQuiz(_prev => {
      const _new = [..._prev];

      _new[_index].userAnswer = _userAnswer;

      return _new;
    })
  }

  async function submit() {
    setLoading(true);
    await callApi("submit-course-quiz-answers", { courseId, contentId, quiz: JSON.stringify(quiz) }).then((response) => {
      if (response.status === 1) {
        appContext.getCurrentUserData();
        appContext.tellMessage(STR_SUCCESSFUL[appContext.language]);
        document.getElementById("_resultsDiv").scrollIntoView({
          behavior: 'smooth',
        })
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  async function getContentData() {
    await callApi("get-course-content-data", { id: contentId }).then(response => {
      if (response.status === 1) {
        setContentData(response.data);
      }
    })
  }

  async function getCourseData() {
    await callApi("get-course-data", { id: courseId }).then(response => {
      if (response.status === 1) {
        setCourseData(response.data);
      }
    })
  }

  useEffect(() => {
    init();
  }, [courseId, contentId])

  useEffect(() => {
    if (contentData) {
      setQuiz(JSON.parse(contentData.quiz || '[]'));
    }
  }, [contentData])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  useEffect(() => {
    setQuizResults(appContext.getCourseQuizResults({ contentId, courseId }))
  }, [appContext.currentUserData, contentId, courseId])

  if (ready && contentData && courseData) {
    return (
      <div className="container">
        <div className="row">

          <div className="col-md-12">
            <h6
              className="mSectionTitle"
              style={{
                borderBottom: "1px solid var(--primaryColor)",
                paddingBottom: "20px",
              }}
            >
              {STR_TAKE_QUIZ[appContext.language]} <br />

              <span
                style={{
                  display: "block",
                  fontSize: "24px",
                }}
                className="font-bold mt-4"
              >
                {courseData.title}
              </span>
            </h6>
          </div>

          <div className="col-md-12">
            <h6
              style={{
                display: "block",
                fontSize: "18px",
              }}
              className="font-bold mt-4 mb-4 text-muted"
            >
              {contentData.title}
            </h6>
          </div>

          <div className="col-md-12" id="_resultsDiv">
            <div
              className="bg-surface mShadow3 text-center mt-2 mb-4"
              style={{
                padding: "20px",
              }}
            >
              {
                (quizResults) ?
                  <h6
                    style={{
                      fontSize: "48px",
                    }}
                    className="font-bold text-secondary"
                  >
                    <span className="text-dark font-semi-bold badge bg-dark" style={{ fontSize: "12px", textTransform: "uppercase" }}>{STR_YOUR_RESULTS[appContext.language]}</span>
                    <br />
                    {quizResults.score}%
                    <br />
                    <span className="text-muted" style={{ fontSize: "16px", }}>{formatDate(quizResults.date)}</span>
                  </h6>
                  :
                  <h6
                    style={{
                      fontSize: "14px",
                    }}
                    className="text-secondary font-semi-bold"
                  >
                    {STR_YOUR_MARKS_WILL_APPEAR_HERE_AFTER_COMPLETING_QUIZ[appContext.language]}
                  </h6>
              }

              <button
                className="btn btn-padded btn-rounded bg-secondary"
                onClick={() => {
                  appContext.navTo({
                    item: 'view',
                    subItem: 'course',
                    extraItem: courseId,
                  })
                }}
              >
                {STR_BACK_TO_COURSE[appContext.language]}
              </button>
            </div>
          </div>

          <div className="col-md-12">
            {
              quiz?.map((item, i) => {
                return (
                  <div className="card" key={i}>
                    <div className="card-body">
                      <h6
                        className=""
                        style={{
                          fontSize: "18px",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.question}
                      </h6>

                      {
                        (item.answerType === 'simple') ?
                          <div
                            className="mt-4"
                          >
                            <select
                              className="form-control"
                              value={item.userAnswer || ''}
                              onChange={(e) => handleQuizAnswering(i, e.target.value)}
                            >
                              <option value=""></option>
                              {
                                shuffleArray(stringToArray(item.multipleChoices + "," + item.answer)).map((choice, choiceIndex) => {
                                  return (<option value={choice} key={choiceIndex}>{choice}</option>)
                                })
                              }
                            </select>
                            <div className="form-text">{STR_SELECT_CORRECT_ANSWER[appContext.language]}</div>
                          </div>
                          :
                          <div
                            className="mt-4"
                          >
                            <input
                              className="form-control"
                              value={item.userAnswer || ''}
                              onChange={(e) => handleQuizAnswering(i, e.target.value)}
                            />
                            <div className="form-text">{STR_WRITE_CORRECT_ANSWER[appContext.language]}</div>
                          </div>
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>

          {
            (!quizResults) ?
              <div
                className="col-md-12 mb-4 text-center bg-surface"
                style={{
                  padding: "30px",
                }}
              >
                <button
                  className="btn btn-secondary btn-padded btn-rounded"
                  onClick={() => {
                    appContext.activateDialog({
                      message: STR_SUBMIT[appContext.language] + "?",
                      onConfirm: submit
                    })
                  }}
                >
                  {STR_SUBMIT[appContext.language]}
                </button>
              </div>
              : ""
          }

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading container"
      >
        {getInlineLoader()}
      </div>
    );
  }


}