import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import {
  STR_CERTIFICATE_PROGRAMS,
  STR_COURSES,
  STR_LATEST_COURSES,
  STR_MORE_COURSES,
  STR_SEARCH_ARTICLES,
  STR_SEARCH_COURSES,
} from "../Strings";
import OneCourse from '../ones/OneCourse';
import OneCertificateProgram from '../ones/OneCertificateProgram';
import { MdSearch } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [latestCourses, setLatestCourses] = useState([]);
  const [moreCourses, setMoreCourses] = useState([]);


  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getCoursesFeed();
    await appContext.getCertificateProgramsFeed();
    setReady(true);
  }

  function processFeed() {
    if (appContext.coursesFeed && appContext.coursesFeed.length > 0) {
      const latestIndexLimit = 5;
      const _latest = [];
      const _more = [];

      for (let i = 0; i < appContext.coursesFeed.length; i++) {
        const _article = appContext.coursesFeed[i];

        if (i < latestIndexLimit) {
          _latest.push(_article);
        } else {
          _more.push(_article);
        }
      }

      setLatestCourses(_latest);
      setMoreCourses(_more);
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    processFeed();
  }, [appContext.coursesFeed])

  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "scroll",
          paddingBottom: "40px",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <div className="row">

            <div className="col-md-12 text-end">
              <button
                className="btn bg-surface btn-padded btn-rounded mt-4"
                onClick={() => {
                  appContext.navTo({
                    item: 'home',
                    subItem: 'courses-search',
                  })
                }}
              >
                <MdSearch size={24} /> {STR_SEARCH_COURSES[appContext.language]}
              </button>
            </div>

            <div className="col-md-12">
              <h1
                className="mSectionTitle"
              >
                {STR_CERTIFICATE_PROGRAMS[appContext.language]}
              </h1>
            </div>

            {
              (appContext.certificateProgramsFeed && appContext.certificateProgramsFeed.length > 0) ?
                appContext.certificateProgramsFeed.map((item, i) => {
                  return (
                    <div
                      className="col-md-4"
                      key={i}
                    >
                      <OneCertificateProgram data={item} />
                    </div>
                  )
                })
                :
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                >
                  No programs were found.
                </h6>
            }

            <div className="col-md-12">
              <h1
                className="mSectionTitle"
              >
                {STR_COURSES[appContext.language]}
              </h1>
            </div>

            {
              (appContext.coursesFeed && appContext.coursesFeed.length > 0) ?
                appContext.coursesFeed.map((item, i) => {
                  return (
                    <div
                      className="col-md-4"
                      key={i}
                    >
                      <OneCourse data={item} size="small" />
                    </div>
                  )
                })
                :
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                >
                  No courses were found.
                </h6>
            }

          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}