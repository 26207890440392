import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { callApi, formatDate, formatMoney, getInlineLoader } from "../Helpers";
import { STR__ORDER_STATUS_INQUIRY, STR_DOWNLOAD, STR_OPEN, STR_ORDER_STATUS_CANCELLED, STR_ORDER_STATUS_DELIVERED, STR_ORDER_STATUS_PENDING, STR_ORDER_STATUS_SUCCESS } from "../Strings";
import Certificate from "./Certificate";

export default function View({ data }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [courseData, setCourseData] = useState(null);

  function open() {
    //..
  }

  function download() {
    //..
  }

  async function getCourseData() {
    await callApi("get-course-data", { id: data.courseId }).then(response => {
      if (response.status === 1) {
        setCourseData(response.data);
      }
    })
  }

  async function init() {
    setReady(false);
    await getCourseData();
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [data])

  if (ready && courseData) {
    return (
      <div className="One">
        <div className="row">
          <div className="col-12 col-md-12 mb-4">
            <h6
              style={{
                fontSize: "22px"
              }}
              className="font-semi-bold"
            >
              {courseData.title}

            </h6>
          </div>

          <div className="col-12 col-md-12">
            <h6
              style={{
                fontSize: "30px"
              }}
              className="font-light"
            >
              {data.averageScore}%
            </h6>

          </div>



          <div className="col-12 mt-4" style={{ overflow: "hidden" }}>
            <Certificate
              courseTitle={courseData.title}
              date={data.date}
              studentName={appContext.currentUserData.fullname}
            />
          </div>

        </div>
      </div>
    )

  } else {
    return (
      <div className="One mSupportLoading">
        {getInlineLoader()}
      </div>
    )

  }
}