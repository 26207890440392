import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import {
  STR_LATEST_COURSES,
  STR_MORE_COURSES,
  STR_NO_ITEMS_FOUND,
  STR_SEARCH_HERE,
  STR_SHOP,
} from "../Strings";
import OneShopItem from '../ones/OneShopItem';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [filter, setFilter] = useState("");

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getShopFeed();
    setReady(true);
  }


  useEffect(() => {
    init();
  }, [])

  useEffect(() => {

  }, [appContext.shopFeed])

  if (ready) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          overflow: "scroll",
          paddingBottom: "40px",
        }}
        className="mNoScrollBar"
      >
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <h1
                className="mSectionTitle"
              >
                {STR_SHOP[appContext.language]}
              </h1>
            </div>

            <div className="col-md-12 mb-4">
              <input
                className="form-control d-block mb-4"
                style={{
                  width: "300px",
                }}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                placeholder={STR_SEARCH_HERE[appContext.language]}
              />
            </div>

            {
              (appContext.shopFeed && appContext.shopFeed.length > 0) ?
                appContext.shopFeed.map((item, i) => {
                  if (filter && filter.trim().length > 0) {
                    if (String(item.title).toLowerCase().indexOf(String(filter).toLowerCase()) > -1) {
                      return (
                        <div
                          className="col-md-4"
                          key={i}
                        >
                          <OneShopItem data={item} size="small" />
                        </div>
                      )
                    } else {
                      return "";
                    }
                  } else {
                    return (
                      <div
                        className="col-md-4"
                        key={i}
                      >
                        <OneShopItem data={item} size="small" />
                      </div>
                    )
                  }

                })
                :
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {STR_NO_ITEMS_FOUND[appContext.language]}
                </h6>
            }



          </div>

        </div>


      </div>
    )
  } else {
    return (
      <div
        className="mSupportLoading"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {getInlineLoader()}
      </div>
    );
  }


}